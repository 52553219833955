@import '@hip/theme/src/css/sizing.css';

.TypeAheadInputWrapper {
  position: relative;
  width: 100%;

  @media (--media-large-desktop) {
    width: 65%;
  }
}

.TypeAheadInput {
  display: inline-block;
  height: 4rem;
  width: 100%;
  padding: var(--page-pad) 1.6rem;
  background-color: white;
  border: var(--border-size) solid #a6a6a6;
  font-size: 1.4rem;
  outline: none;
}
