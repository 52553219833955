body,
html {
  /* iframeResizer lib requires this property */
  height: initial;
}

.Layout {
  display: flex !important;
  flex-direction: column;
  padding: 0;
}

.Content {
  flex-grow: 1;
}
