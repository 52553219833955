@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/sizing.css';

.StepsWrapper {
  background-color: var(--color-suede);
}

.StepsText {
  padding: var(--page-pad-plus) var(--page-pad-tripple);
  white-space: nowrap;
}
