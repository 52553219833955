@import '@hip/theme/src/css/media.css';
@import '@hip/theme/src/css/sizing.css';

.CalendarWrapper {
  width: 100%;
  background-color: white;
  padding: 2rem 0;

  @media (--media-tablet) {
    padding: 2rem 2.4rem;
  }
}

.BoardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4.5rem 2rem;
  min-height: 35.8rem;

  @media (--media-tablet) {
    min-height: 49.8rem;
  }
}
