.Menu {
  background-color: #fff;
  position: absolute !important;
  left: 0;
  right: 0;
  z-index: 1000;
  border-radius: var(--border-size-medium);
  border-top: var(--border-size) solid #d9d9d9;
  font-family: Arial, sans-serif;
  box-shadow: 0 var(--border-size-medium) 0.6rem rgb(0 0 0 / 30%);
  box-sizing: border-box;
  overflow: hidden;
}
