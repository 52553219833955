:root {
  --country-code-value-width: 12rem;
  --dropdown-height: 20rem;
}

.IntlPhoneInputValue {
  width: var(--country-code-value-width);
  border-right-color: transparent;
}

.IntlPhoneInputValue:hover + .IntlPhoneNumberInput {
  border-left-color: transparent;
}

.IntlPhoneNumberInput {
  width: auto;
  flex-grow: 1;
}

.IntlPhoneInputDropdown {
  height: var(--dropdown-height);
  overflow-y: scroll;
  position: absolute;
  left: 0;
  top: 0;
}
