@import '@hip/theme/src/css/colors.css';

.IntlPhoneNumberInput__Option {
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--color-athens-gray);
  }
}
