.Button {
  max-width: 18.4rem !important;
  width: 100%;
  min-width: 10rem !important;
  min-height: 4rem !important;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.PrimaryButton {
  background-color: var(--color-primary) !important;
}

.PrimaryButton:active {
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
}

.SecondaryButton {
  border: 2px solid var(--color-slate) !important;
}

.DisabledButton {
  pointer-events: initial !important;
  cursor: not-allowed !important;
  box-shadow: none;
}

.PrimaryButton.DisabledButton {
  opacity: 0.65;
  color: white !important;
}

.SecondaryButton.DisabledButton {
  background: #eaebec !important;
  color: #959a9d !important;
}

@media (max-width: 487px) {
  .Button {
    max-width: 100% !important;
  }
}
