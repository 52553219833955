@import '@hip/theme/src/css/colors.css';

.Header {
  background-color: var(--color-suede);

  @media only screen and (max-width: 767px) {
    .HeaderTitle {
      font-size: 3.6rem;
    }
  }
}

.HeaderContent {
  padding: 4rem 3.2rem 5.6rem;
}
