@import '@hip/theme/src/css/media.css';
@import '@hip/theme/src/css/colors.css';

.CategoryButton {
  background-color: white;
  box-shadow: 0 0 0.8rem 0 rgb(0 0 0 / 15%);
  cursor: pointer;
  overflow: hidden;
}

.CategoryButton:hover {
  background-color: var(--color-slate);
  color: white;
}

.CategoryButtonIconWrapper {
  height: 16rem;
  height: 17.5rem;
  overflow: hidden;
  text-align: center;

  @media (max-width: 20rem) {
    height: 16rem;
  }

  @media (--media-desktop) {
    height: 13.5vw;
  }

  @media (--media-large-desktop) {
    height: 17.5rem;
  }
}

.CategoryButtonIcon {
  width: 100%;
  max-width: 24rem;
}

.CategoryButton:hover .CategoryButtonIcon {
  transform: translateY(-50%);
}

.CategoryButtonTitle {
  margin: 1rem 0;
  min-height: 4.6rem;
  font-size: 1.8rem;
  text-align: center;
}
