@import '@hip/theme/src/css/fonts.css';
@import '@hip/theme/src/css/sizing.css';

.MenuItem {
  display: flex;
  align-items: center;
  cursor: default;
  padding: 0 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: var(--sizing-xxl);
  text-align: left;
  border-top: var(--border-size) solid #e6e6e6;
  font-family: var(--font-family-monospace);
  font-size: 1.3rem;
  color: black;
}

.MenuItemIcon {
  margin-right: 0.4rem;
}

.MenuItemLabel {
  padding-right: 0.3rem;
}

.MenuItemIcon,
.MenuItemSub {
  font-size: 1.1rem;
  color: #999;
}

.MenuItem:hover,
.MenuItemActive {
  background-color: #fafafa;
}
