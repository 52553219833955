.HeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NavButton:disabled {
  cursor: not-allowed;
  pointer-events: all;

  &:hover {
    background-color: unset;
  }
}
