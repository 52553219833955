@import '@hip/theme/src/css/media.css';

.CancelBooking {
  flex-flow: column;
  max-width: 102.4rem;

  @media (--media-desktop) {
    flex-flow: row;
    margin-left: auto;
    margin-right: auto;
  }
}

.CancelBookingCTA {
  flex-direction: column;

  @media (--media-desktop) {
    flex-direction: row-reverse;
    align-items: center;
  }
}

.CancelBookingCTABtnCancel {
  @media (--media-desktop) {
    padding-top: 0;
  }
}

.CancelBookingCTABtnConfirm {
  max-width: 0;
}
