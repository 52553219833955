@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/media.css';
@import '@hip/theme/src/css/sizing.css';

.Cell {
  height: 4.45rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  outline: 1px solid #adb1b3;
  cursor: pointer;

  @media (--media-tablet) {
    height: 7.2rem;
  }
}

.Cell_selected,
.Cell:hover {
  background-color: var(--color-slate);
  color: white;
}

.Cell_disabled,
.Cell_disabled:hover {
  background-color: #e5e5e5;
  color: var(--color-slate);
  cursor: not-allowed;
}

.Today {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.4rem;
  height: 3.4rem;
  background-color: var(--color-slate);
  color: white;
  border-radius: 50%;
}

.Cell_disabled .Today,
.Cell_selected .Today,
.Cell:hover .Today {
  background-color: white;
  color: var(--color-slate);
}
