@import '@hip/theme/src/css/sizing.css';

.HeaderWrapper {
  background-color: var(--color-clay-20);
}

.SummaryWrapper {
  width: 40%;
  margin: auto;
}

.PrintButton > span {
  display: flex;
}

@media print {
  .PrintButton {
    display: none;
  }
}

@media (--media-desktop-down) {
  .SummaryWrapper table {
    table-layout: fixed;
  }

  .SummaryWrapper {
    width: 60%;
  }
}

@media (--media-mobile) {
  .SummaryWrapper {
    width: 100%;
  }
}

.Table td {
  white-space: pre-wrap;
  word-wrap: break-word;
  border: none;
  padding-top: var(--page-pad-double);
  padding-right: var(--page-pad-double-plus);
  vertical-align: text-top;
}

.Table th {
  border: none;
  padding-top: var(--page-pad-double);
  padding-left: var(--page-pad-double-plus);
  vertical-align: text-top;
}

.Table tr:last-child th {
  padding-bottom: var(--page-pad-double);
}

.Table tr:last-child td {
  padding-bottom: var(--page-pad-double);
}
