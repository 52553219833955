@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/media.css';
@import '@hip/theme/src/css/sizing.css';

:root {
  --map-height: 34rem;
}

.ResultsWrapper {
  display: flex;
  justify-content: center;
  min-height: 35rem;
}

.StoreList {
  width: 40%;
  align-items: initial;
}

@media (max-width: 45rem) {
  div.Container {
    padding: 0;
  }

  .ResultsWrapper {
    flex-direction: column;
  }

  .MapWrapper {
    width: 100%;
  }

  .StoreList {
    width: 100%;
    padding: var(--page-pad-double) 0px;
  }

  .Map {
    padding-top: var(--page-pad-double);
  }
}

@media (min-width: 45rem) {
  .MapWrapper {
    display: flex;
    padding: var(--page-pad-double-plus);
  }
}
