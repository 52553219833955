@import '@hip/theme/src/css/colors.css';

:root {
  --form-width: 40rem;
}

.PersonalDetailsFormWrapper {
  max-width: var(--form-width);
}

.PrivacyPolicy {
  background-color: var(--color-suede);
}

@media (max-width: 64em) {
  .GridItemWithBookingInfo {
    order: -1;
  }
}
