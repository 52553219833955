.DatesWrapper,
.DaysOfWeekWrapper {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.DatesWrapper {
  gap: 1px;
  padding: 1px;
}
