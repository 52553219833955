@import '@hip/theme/src/css/colors.css';

.FlagIcon__Wrapper {
  height: 14px;
  width: 20px;
  background-size: 5652px 15px;
  display: flex;
  align-items: center;
  overflow: hidden;
  box-shadow: var(--color-slate-20) 0 0 1px 0;
}

.Flag__be {
  width: 18px;
}
.Flag__ch {
  width: 15px;
}
.Flag__mc {
  width: 19px;
}
.Flag__ne {
  width: 18px;
}
.Flag__np {
  width: 13px;
}
.Flag__va {
  width: 15px;
}
.Flag__ac {
  height: 10px;
  background-position: 0 0;
}
.Flag__ad {
  height: 14px;
  background-position: -22px 0;
}
.Flag__ae {
  height: 10px;
  background-position: -44px 0;
}
.Flag__af {
  height: 14px;
  background-position: -66px 0;
}
.Flag__ag {
  height: 14px;
  background-position: -88px 0;
}
.Flag__ai {
  height: 10px;
  background-position: -110px 0;
}
.Flag__al {
  height: 15px;
  background-position: -132px 0;
}
.Flag__am {
  height: 10px;
  background-position: -154px 0;
}
.Flag__ao {
  height: 14px;
  background-position: -176px 0;
}
.Flag__aq {
  height: 14px;
  background-position: -198px 0;
}
.Flag__ar {
  height: 13px;
  background-position: -220px 0;
}
.Flag__as {
  height: 10px;
  background-position: -242px 0;
}
.Flag__at {
  height: 14px;
  background-position: -264px 0;
}
.Flag__au {
  height: 10px;
  background-position: -286px 0;
}
.Flag__aw {
  height: 14px;
  background-position: -308px 0;
}
.Flag__ax {
  height: 13px;
  background-position: -330px 0;
}
.Flag__az {
  height: 10px;
  background-position: -352px 0;
}
.Flag__ba {
  height: 10px;
  background-position: -374px 0;
}
.Flag__bb {
  height: 14px;
  background-position: -396px 0;
}
.Flag__bd {
  height: 12px;
  background-position: -418px 0;
}
.Flag__be {
  height: 15px;
  background-position: -440px 0;
}
.Flag__bf {
  height: 14px;
  background-position: -460px 0;
}
.Flag__bg {
  height: 12px;
  background-position: -482px 0;
}
.Flag__bh {
  height: 12px;
  background-position: -504px 0;
}
.Flag__bi {
  height: 12px;
  background-position: -526px 0;
}
.Flag__bj {
  height: 14px;
  background-position: -548px 0;
}
.Flag__bl {
  height: 14px;
  background-position: -570px 0;
}
.Flag__bm {
  height: 10px;
  background-position: -592px 0;
}
.Flag__bn {
  height: 10px;
  background-position: -614px 0;
}
.Flag__bo {
  height: 14px;
  background-position: -636px 0;
}
.Flag__bq {
  height: 14px;
  background-position: -658px 0;
}
.Flag__br {
  height: 14px;
  background-position: -680px 0;
}
.Flag__bs {
  height: 10px;
  background-position: -702px 0;
}
.Flag__bt {
  height: 14px;
  background-position: -724px 0;
}
.Flag__bv {
  height: 15px;
  background-position: -746px 0;
}
.Flag__bw {
  height: 14px;
  background-position: -768px 0;
}
.Flag__by {
  height: 10px;
  background-position: -790px 0;
}
.Flag__bz {
  height: 14px;
  background-position: -812px 0;
}
.Flag__ca {
  height: 10px;
  background-position: -834px 0;
}
.Flag__cc {
  height: 10px;
  background-position: -856px 0;
}
.Flag__cd {
  height: 15px;
  background-position: -878px 0;
}
.Flag__cf {
  height: 14px;
  background-position: -900px 0;
}
.Flag__cg {
  height: 14px;
  background-position: -922px 0;
}
.Flag__ch {
  height: 15px;
  background-position: -944px 0;
}
.Flag__ci {
  height: 14px;
  background-position: -961px 0;
}
.Flag__ck {
  height: 10px;
  background-position: -983px 0;
}
.Flag__cl {
  height: 14px;
  background-position: -1005px 0;
}
.Flag__cm {
  height: 14px;
  background-position: -1027px 0;
}
.Flag__cn {
  height: 14px;
  background-position: -1049px 0;
}
.Flag__co {
  height: 14px;
  background-position: -1071px 0;
}
.Flag__cp {
  height: 14px;
  background-position: -1093px 0;
}
.Flag__cr {
  height: 12px;
  background-position: -1115px 0;
}
.Flag__cu {
  height: 10px;
  background-position: -1137px 0;
}
.Flag__cv {
  height: 12px;
  background-position: -1159px 0;
}
.Flag__cw {
  height: 14px;
  background-position: -1181px 0;
}
.Flag__cx {
  height: 10px;
  background-position: -1203px 0;
}
.Flag__cy {
  height: 14px;
  background-position: -1225px 0;
}
.Flag__cz {
  height: 14px;
  background-position: -1247px 0;
}
.Flag__de {
  height: 12px;
  background-position: -1269px 0;
}
.Flag__dg {
  height: 10px;
  background-position: -1291px 0;
}
.Flag__dj {
  height: 14px;
  background-position: -1313px 0;
}
.Flag__dk {
  height: 15px;
  background-position: -1335px 0;
}
.Flag__dm {
  height: 10px;
  background-position: -1357px 0;
}
.Flag__do {
  height: 14px;
  background-position: -1379px 0;
}
.Flag__dz {
  height: 14px;
  background-position: -1401px 0;
}
.Flag__ea {
  height: 14px;
  background-position: -1423px 0;
}
.Flag__ec {
  height: 14px;
  background-position: -1445px 0;
}
.Flag__ee {
  height: 13px;
  background-position: -1467px 0;
}
.Flag__eg {
  height: 14px;
  background-position: -1489px 0;
}
.Flag__eh {
  height: 10px;
  background-position: -1511px 0;
}
.Flag__er {
  height: 10px;
  background-position: -1533px 0;
}
.Flag__es {
  height: 14px;
  background-position: -1555px 0;
}
.Flag__et {
  height: 10px;
  background-position: -1577px 0;
}
.Flag__eu {
  height: 14px;
  background-position: -1599px 0;
}
.Flag__fi {
  height: 12px;
  background-position: -1621px 0;
}
.Flag__fj {
  height: 10px;
  background-position: -1643px 0;
}
.Flag__fk {
  height: 10px;
  background-position: -1665px 0;
}
.Flag__fm {
  height: 11px;
  background-position: -1687px 0;
}
.Flag__fo {
  height: 15px;
  background-position: -1709px 0;
}
.Flag__fr {
  height: 14px;
  background-position: -1731px 0;
}
.Flag__ga {
  height: 15px;
  background-position: -1753px 0;
}
.Flag__gb {
  height: 10px;
  background-position: -1775px 0;
}
.Flag__gd {
  height: 12px;
  background-position: -1797px 0;
}
.Flag__ge {
  height: 14px;
  background-position: -1819px 0;
}
.Flag__gf {
  height: 14px;
  background-position: -1841px 0;
}
.Flag__gg {
  height: 14px;
  background-position: -1863px 0;
}
.Flag__gh {
  height: 14px;
  background-position: -1885px 0;
}
.Flag__gi {
  height: 10px;
  background-position: -1907px 0;
}
.Flag__gl {
  height: 14px;
  background-position: -1929px 0;
}
.Flag__gm {
  height: 14px;
  background-position: -1951px 0;
}
.Flag__gn {
  height: 14px;
  background-position: -1973px 0;
}
.Flag__gp {
  height: 14px;
  background-position: -1995px 0;
}
.Flag__gq {
  height: 14px;
  background-position: -2017px 0;
}
.Flag__gr {
  height: 14px;
  background-position: -2039px 0;
}
.Flag__gs {
  height: 10px;
  background-position: -2061px 0;
}
.Flag__gt {
  height: 13px;
  background-position: -2083px 0;
}
.Flag__gu {
  height: 11px;
  background-position: -2105px 0;
}
.Flag__gw {
  height: 10px;
  background-position: -2127px 0;
}
.Flag__gy {
  height: 12px;
  background-position: -2149px 0;
}
.Flag__hk {
  height: 14px;
  background-position: -2171px 0;
}
.Flag__hm {
  height: 10px;
  background-position: -2193px 0;
}
.Flag__hn {
  height: 10px;
  background-position: -2215px 0;
}
.Flag__hr {
  height: 10px;
  background-position: -2237px 0;
}
.Flag__ht {
  height: 12px;
  background-position: -2259px 0;
}
.Flag__hu {
  height: 10px;
  background-position: -2281px 0;
}
.Flag__ic {
  height: 14px;
  background-position: -2303px 0;
}
.Flag__id {
  height: 14px;
  background-position: -2325px 0;
}
.Flag__ie {
  height: 10px;
  background-position: -2347px 0;
}
.Flag__il {
  height: 15px;
  background-position: -2369px 0;
}
.Flag__im {
  height: 10px;
  background-position: -2391px 0;
}
.Flag__in {
  height: 14px;
  background-position: -2413px 0;
}
.Flag__io {
  height: 10px;
  background-position: -2435px 0;
}
.Flag__iq {
  height: 14px;
  background-position: -2457px 0;
}
.Flag__ir {
  height: 12px;
  background-position: -2479px 0;
}
.Flag__is {
  height: 15px;
  background-position: -2501px 0;
}
.Flag__it {
  height: 14px;
  background-position: -2523px 0;
}
.Flag__je {
  height: 12px;
  background-position: -2545px 0;
}
.Flag__jm {
  height: 10px;
  background-position: -2567px 0;
}
.Flag__jo {
  height: 10px;
  background-position: -2589px 0;
}
.Flag__jp {
  height: 14px;
  background-position: -2611px 0;
}
.Flag__ke {
  height: 14px;
  background-position: -2633px 0;
}
.Flag__kg {
  height: 12px;
  background-position: -2655px 0;
}
.Flag__kh {
  height: 13px;
  background-position: -2677px 0;
}
.Flag__ki {
  height: 10px;
  background-position: -2699px 0;
}
.Flag__km {
  height: 12px;
  background-position: -2721px 0;
}
.Flag__kn {
  height: 14px;
  background-position: -2743px 0;
}
.Flag__kp {
  height: 10px;
  background-position: -2765px 0;
}
.Flag__kr {
  height: 14px;
  background-position: -2787px 0;
}
.Flag__kw {
  height: 10px;
  background-position: -2809px 0;
}
.Flag__ky {
  height: 10px;
  background-position: -2831px 0;
}
.Flag__kz {
  height: 10px;
  background-position: -2853px 0;
}
.Flag__la {
  height: 14px;
  background-position: -2875px 0;
}
.Flag__lb {
  height: 14px;
  background-position: -2897px 0;
}
.Flag__lc {
  height: 10px;
  background-position: -2919px 0;
}
.Flag__li {
  height: 12px;
  background-position: -2941px 0;
}
.Flag__lk {
  height: 10px;
  background-position: -2963px 0;
}
.Flag__lr {
  height: 11px;
  background-position: -2985px 0;
}
.Flag__ls {
  height: 14px;
  background-position: -3007px 0;
}
.Flag__lt {
  height: 12px;
  background-position: -3029px 0;
}
.Flag__lu {
  height: 12px;
  background-position: -3051px 0;
}
.Flag__lv {
  height: 10px;
  background-position: -3073px 0;
}
.Flag__ly {
  height: 10px;
  background-position: -3095px 0;
}
.Flag__ma {
  height: 14px;
  background-position: -3117px 0;
}
.Flag__mc {
  height: 15px;
  background-position: -3139px 0;
}
.Flag__md {
  height: 10px;
  background-position: -3160px 0;
}
.Flag__me {
  height: 10px;
  background-position: -3182px 0;
}
.Flag__mf {
  height: 14px;
  background-position: -3204px 0;
}
.Flag__mg {
  height: 14px;
  background-position: -3226px 0;
}
.Flag__mh {
  height: 11px;
  background-position: -3248px 0;
}
.Flag__mk {
  height: 10px;
  background-position: -3270px 0;
}
.Flag__ml {
  height: 14px;
  background-position: -3292px 0;
}
.Flag__mm {
  height: 14px;
  background-position: -3314px 0;
}
.Flag__mn {
  height: 10px;
  background-position: -3336px 0;
}
.Flag__mo {
  height: 14px;
  background-position: -3358px 0;
}
.Flag__mp {
  height: 10px;
  background-position: -3380px 0;
}
.Flag__mq {
  height: 14px;
  background-position: -3402px 0;
}
.Flag__mr {
  height: 14px;
  background-position: -3424px 0;
}
.Flag__ms {
  height: 10px;
  background-position: -3446px 0;
}
.Flag__mt {
  height: 14px;
  background-position: -3468px 0;
}
.Flag__mu {
  height: 14px;
  background-position: -3490px 0;
}
.Flag__mv {
  height: 14px;
  background-position: -3512px 0;
}
.Flag__mw {
  height: 14px;
  background-position: -3534px 0;
}
.Flag__mx {
  height: 12px;
  background-position: -3556px 0;
}
.Flag__my {
  height: 10px;
  background-position: -3578px 0;
}
.Flag__mz {
  height: 14px;
  background-position: -3600px 0;
}
.Flag__na {
  height: 14px;
  background-position: -3622px 0;
}
.Flag__nc {
  height: 10px;
  background-position: -3644px 0;
}
.Flag__ne {
  height: 15px;
  background-position: -3666px 0;
}
.Flag__nf {
  height: 10px;
  background-position: -3686px 0;
}
.Flag__ng {
  height: 10px;
  background-position: -3708px 0;
}
.Flag__ni {
  height: 12px;
  background-position: -3730px 0;
}
.Flag__nl {
  height: 14px;
  background-position: -3752px 0;
}
.Flag__no {
  height: 15px;
  background-position: -3774px 0;
}
.Flag__np {
  height: 15px;
  background-position: -3796px 0;
}
.Flag__nr {
  height: 10px;
  background-position: -3811px 0;
}
.Flag__nu {
  height: 10px;
  background-position: -3833px 0;
}
.Flag__nz {
  height: 10px;
  background-position: -3855px 0;
}
.Flag__om {
  height: 10px;
  background-position: -3877px 0;
}
.Flag__pa {
  height: 14px;
  background-position: -3899px 0;
}
.Flag__pe {
  height: 14px;
  background-position: -3921px 0;
}
.Flag__pf {
  height: 14px;
  background-position: -3943px 0;
}
.Flag__pg {
  height: 15px;
  background-position: -3965px 0;
}
.Flag__ph {
  height: 10px;
  background-position: -3987px 0;
}
.Flag__pk {
  height: 14px;
  background-position: -4009px 0;
}
.Flag__pl {
  height: 13px;
  background-position: -4031px 0;
}
.Flag__pm {
  height: 14px;
  background-position: -4053px 0;
}
.Flag__pn {
  height: 10px;
  background-position: -4075px 0;
}
.Flag__pr {
  height: 14px;
  background-position: -4097px 0;
}
.Flag__ps {
  height: 10px;
  background-position: -4119px 0;
}
.Flag__pt {
  height: 14px;
  background-position: -4141px 0;
}
.Flag__pw {
  height: 13px;
  background-position: -4163px 0;
}
.Flag__py {
  height: 11px;
  background-position: -4185px 0;
}
.Flag__qa {
  height: 8px;
  background-position: -4207px 0;
}
.Flag__re {
  height: 14px;
  background-position: -4229px 0;
}
.Flag__ro {
  height: 14px;
  background-position: -4251px 0;
}
.Flag__rs {
  height: 14px;
  background-position: -4273px 0;
}
.Flag__ru {
  height: 14px;
  background-position: -4295px 0;
}
.Flag__rw {
  height: 14px;
  background-position: -4317px 0;
}
.Flag__sa {
  height: 14px;
  background-position: -4339px 0;
}
.Flag__sb {
  height: 10px;
  background-position: -4361px 0;
}
.Flag__sc {
  height: 10px;
  background-position: -4383px 0;
}
.Flag__sd {
  height: 10px;
  background-position: -4405px 0;
}
.Flag__se {
  height: 13px;
  background-position: -4427px 0;
}
.Flag__sg {
  height: 14px;
  background-position: -4449px 0;
}
.Flag__sh {
  height: 10px;
  background-position: -4471px 0;
}
.Flag__si {
  height: 10px;
  background-position: -4493px 0;
}
.Flag__sj {
  height: 15px;
  background-position: -4515px 0;
}
.Flag__sk {
  height: 14px;
  background-position: -4537px 0;
}
.Flag__sl {
  height: 14px;
  background-position: -4559px 0;
}
.Flag__sm {
  height: 15px;
  background-position: -4581px 0;
}
.Flag__sn {
  height: 14px;
  background-position: -4603px 0;
}
.Flag__so {
  height: 14px;
  background-position: -4625px 0;
}
.Flag__sr {
  height: 14px;
  background-position: -4647px 0;
}
.Flag__ss {
  height: 10px;
  background-position: -4669px 0;
}
.Flag__st {
  height: 10px;
  background-position: -4691px 0;
}
.Flag__sv {
  height: 12px;
  background-position: -4713px 0;
}
.Flag__sx {
  height: 14px;
  background-position: -4735px 0;
}
.Flag__sy {
  height: 14px;
  background-position: -4757px 0;
}
.Flag__sz {
  height: 14px;
  background-position: -4779px 0;
}
.Flag__ta {
  height: 10px;
  background-position: -4801px 0;
}
.Flag__tc {
  height: 10px;
  background-position: -4823px 0;
}
.Flag__td {
  height: 14px;
  background-position: -4845px 0;
}
.Flag__tf {
  height: 14px;
  background-position: -4867px 0;
}
.Flag__tg {
  height: 13px;
  background-position: -4889px 0;
}
.Flag__th {
  height: 14px;
  background-position: -4911px 0;
}
.Flag__tj {
  height: 10px;
  background-position: -4933px 0;
}
.Flag__tk {
  height: 10px;
  background-position: -4955px 0;
}
.Flag__tl {
  height: 10px;
  background-position: -4977px 0;
}
.Flag__tm {
  height: 14px;
  background-position: -4999px 0;
}
.Flag__tn {
  height: 14px;
  background-position: -5021px 0;
}
.Flag__to {
  height: 10px;
  background-position: -5043px 0;
}
.Flag__tr {
  height: 14px;
  background-position: -5065px 0;
}
.Flag__tt {
  height: 12px;
  background-position: -5087px 0;
}
.Flag__tv {
  height: 10px;
  background-position: -5109px 0;
}
.Flag__tw {
  height: 14px;
  background-position: -5131px 0;
}
.Flag__tz {
  height: 14px;
  background-position: -5153px 0;
}
.Flag__ua {
  height: 14px;
  background-position: -5175px 0;
}
.Flag__ug {
  height: 14px;
  background-position: -5197px 0;
}
.Flag__um {
  height: 11px;
  background-position: -5219px 0;
}
.Flag__un {
  height: 14px;
  background-position: -5241px 0;
}
.Flag__us {
  height: 11px;
  background-position: -5263px 0;
}
.Flag__uy {
  height: 14px;
  background-position: -5285px 0;
}
.Flag__uz {
  height: 10px;
  background-position: -5307px 0;
}
.Flag__va {
  height: 15px;
  background-position: -5329px 0;
}
.Flag__vc {
  height: 14px;
  background-position: -5346px 0;
}
.Flag__ve {
  height: 14px;
  background-position: -5368px 0;
}
.Flag__vg {
  height: 10px;
  background-position: -5390px 0;
}
.Flag__vi {
  height: 14px;
  background-position: -5412px 0;
}
.Flag__vn {
  height: 14px;
  background-position: -5434px 0;
}
.Flag__vu {
  height: 12px;
  background-position: -5456px 0;
}
.Flag__wf {
  height: 14px;
  background-position: -5478px 0;
}
.Flag__ws {
  height: 10px;
  background-position: -5500px 0;
}
.Flag__xk {
  height: 15px;
  background-position: -5522px 0;
}
.Flag__ye {
  height: 14px;
  background-position: -5544px 0;
}
.Flag__yt {
  height: 14px;
  background-position: -5566px 0;
}
.Flag__za {
  height: 14px;
  background-position: -5588px 0;
}
.Flag__zm {
  height: 14px;
  background-position: -5610px 0;
}
.Flag__zw {
  height: 10px;
  background-position: -5632px 0;
}
