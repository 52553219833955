@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/sizing.css';

ul.Accordion {
  border-bottom: none;
  max-width: 37.6rem;
  width: 100%;
}

.Accordion > li {
  background-color: white;
}

.Accordion > li:not(:last-child) {
  margin-bottom: 1.6rem;
}

.Accordion h2 {
  line-height: 0;
}

.Accordion h2 svg {
  display: none;
}

.Accordion h2 > button {
  padding: 0;
  border: none;
  min-height: initial;
}

.Accordion h2 > button[aria-expanded='true'] .AccordionItemHeader {
  padding-bottom: 0.8rem;
}

.AccordionItemHeader {
  width: 100%;
  padding: 3.2rem;
  display: flex;
  justify-content: space-between;
}

.StoreCardWrapper {
  padding: 0 3.2rem 3.2rem 3.2rem;
}

.DistanceFromUserText {
  flex-shrink: 0;
  line-height: 2;
}

@media (max-width: 992px) {
  ul.Accordion {
    max-width: initial;
  }
}

@media (max-width: 45rem) {
  ul.Accordion {
    padding-right: 0;
  }
}
