@import '@hip/theme/src/css/sizing.css';

.Map {
  width: 100%;
  height: var(--map-height);
  margin-top: 1rem;

  @media (--media-tablet) {
    margin: 0;
  }
}
