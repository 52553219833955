.LegendWrapper {
  padding-top: 2rem;
  text-align: center;

  @media (--media-tablet) {
    height: 7.2rem;
    text-align: left;
  }
}

.LegendItem:not(:first-child) {
  margin-left: 1rem;

  @media (--media-tablet) {
    margin-left: 5rem;
  }
}

.LegendItem::before {
  content: '';
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
  border: thin solid #adb1b3;
  vertical-align: top;
}

.LegendItem_unavailable::before {
  background-color: #e5e5e5;
}
