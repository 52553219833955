@import '@hip/theme/src/css/fonts.css';

.LocationSearchWrapper {
  margin-top: 1rem;
  padding: 4rem 3.2rem;
  background: white;
}

.LocationSearch {
  display: flex;
}

.LocationSearchSubmit {
  margin-left: 1.6rem;
  height: 4rem;
  font-family: var(--font-family-bold);
  font-size: 1.8rem;
}
