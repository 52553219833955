@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/colors.css';

.Progress {
  height: 0.4rem;
  background-color: var(--color-white);
}

.ProgressBar {
  height: 100%;
  width: 0;
  transition: width 0.6s ease;
}
