@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/sizing.css';

.Footer {
  margin-top: 2rem;
  padding: 3.2rem;
  display: flex;
  justify-content: flex-end;
  background-color: var(--color-suede);
}

.PrimaryButton {
  margin-left: 2.4rem;
}

@media (max-width: 487px) {
  .Footer {
    flex-direction: column;
  }

  .SecondaryButton {
    margin-bottom: var(--page-pad-tripple);
  }

  .PrimaryButton {
    margin-left: 0;
  }
}
